<template>
  <div>
    
    <div class="d-flex flex-row">
      <v-icon>mdi-contain-start</v-icon>
      <div v-for="(item, index) in nodes" :key="index">
        <div cols="3" v-if="!item.hasOwnProperty('nodes')">
          <v-text-field
            :data-cy="name + '_' + index"
            :name="name"
            outlined
            type="number"
            :rules="item.required === true ? req : []"
            @input="setVal($event, item)"
            :value="getVal(index)"
            dense
          ></v-text-field>
        </div>
        <div v-else>
          <ListField
            :data-cy="item.name + '_' + index"
            :name="name"
            :nodes="item.nodes"
            :value="item.value"
            :required="item.required"
            :valuePath="[...valuePath, index]"
            :isNestedFormMultiple="isNestedFormMultiple"
          ></ListField>
        </div>
      </div>
      <v-icon>mdi-contain-end</v-icon>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  name: 'ListField',

  data: () => ({
    req: [(v) => !!v || v === 0 || 'required'],
  }),

  props: {
    value: [Array, String, Number],
    nodes: Array,
    label: String,
    name: String,
    unit: String,
    required: Boolean,
    valuePath: Array,
    isNestedFormMultiple: Boolean,
  },

  methods: {
    ...mapMutations('dynamicForms', ['setListValueInMultiple', 'setListValue']),

    setVal(value, item) {
      let index = item.id
      let path = cloneDeep(this.valuePath)
      path.push(index)
      if (this.isNestedFormMultiple) {
        this.setListValueInMultiple([path, value, index])
      } else {
        this.setListValue([path, value, index])
      }
    },

    getVal(index) {
      let path = cloneDeep(this.valuePath)
      path.push(index)
      if (this.isNestedFormMultiple) {
        return this.getListValueFromMultiple(path)
      } else {
        return this.getNestedValue(path)
      }
    },
  },

  computed: {
    ...mapGetters('dynamicForms', ['getNestedValue', 'getListValueFromMultiple']),

    ...mapState('dynamicForms', {
      valueInState: (state) => state.value,
    }),
  },
}
</script>

<style>
.v-expansion-panel-header {
  font-weight: bold;
}
</style>
